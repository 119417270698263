.header-menu {
  @apply z-1;

  // &.open-header-menu {
  // 	@apply fixed;
  // }

  li {
    @apply relative;

    a {
      @apply p-0;
      @apply pb-p-header-menu;
      transition: all 0.3s ease;

      &:hover {
        @apply bg-transparent;

        &::after {
          @apply w-full;
        }
      }

      &::after {
        content: '';
        @apply inline;
        @apply h-header-menu;
        @apply w-0;
        transition: all 0.3s ease;
      }
    }

    &:first-child {
      @apply m-0;
    }
  }

  .menu-icon {
    @apply cursor-pointer;
    right: 0.25rem;
    top: 0.25rem;
    @apply select-none;
    @apply px-2.5;
    @apply py-5;
    @apply bg-transparent;
    @apply border-0;
    font-size: 0;

    &:focus {
      @apply outline-none;
    }

    .navicon {
      @apply bg-primary;
      @apply block;
      @apply h-0.5;
      @apply relative;
      transition: all 0.2s ease-out;
      @apply w-5;

      &::before,
      &::after {
        @apply bg-primary;
        content: '';
        @apply block;
        @apply h-full;
        @apply absolute;
        transition: all 0.2s ease-out;
        @apply w-full;
      }

      &::before {
        @apply top-2;
      }

      &::after {
        @apply -top-2;
      }
    }

    &.close {
      .navicon {
        @apply bg-transparent;

        &::before {
          @apply transform;
          @apply -rotate-45;
        }

        &::after {
          @apply transform;
          @apply rotate-45;
        }
      }

      &:not(.steps) .navicon::before,
      &:not(.steps) .navicon::after {
        @apply top-0;
      }
    }
  }

  .menu {
    @apply hidden;
    @apply bg-allgemein-light;

    &.open {
      @apply flex;
      @apply flex-col;
      @apply absolute;
      @apply fixed;
      @apply top-0;
      @apply inset-x-0;
      @apply w-full;
      @apply h-auto;
      height: calc(var(--vh, 1vh) * 100);
      @apply bg-seeseitn-light;
      @apply overflow-auto;
      @apply z-2;
			
      .contact-wave-background {
        @apply fixed;
      }
			
      .menu-icon {
        @apply fixed;
      }

      .menu-wrapper {
        @apply justify-between;
        @apply h-auto;
        @apply overflow-auto;
        -webkit-overflow-scrolling: touch;
        @apply flex-grow;
      }

      .main-menu-list {
        align-items: flex-start !important;
        @apply flex-col;
        @apply pt-8;
        @apply pb-4;
        @apply px-p-header-menu;

        @media (min-width: 1280px) {
          @apply pt-p-menu-li;
          @apply px-4;
        }

        li {
          @apply w-full;
          @apply h-menu-li;
					
          @media (min-width: 1280px) { 
            @apply w-full;
            @apply h-menu-li-large;
          }
					
          a {
            @apply w-full;
            @apply h-full;
            @apply justify-center;
          }
        }
      }

      .theme-menu-list {
        align-items: flex-start !important;
        @apply flex-col;
        @apply pt-4;
        @apply pb-8;
        @apply px-p-header-menu;

        @media (min-width: 768px) {
          @apply pt-26.5;
        }

        @media (min-width: 1280px) {
          @apply pt-26.5;
          @apply pb-30;
          @apply px-4;
        }

        li {
          @apply w-full;
          @apply h-theme-menu-li;

          @media (min-width: 1280px) { 
            @apply w-full;
            @apply h-theme-menu-li-mobile;
          }

          a {
            @apply w-full;
            @apply h-full;
            @apply justify-center;
          }
        }
      }
    }
  }

  .nav-menu {
    @apply flex-col;
    @apply h-full;
    @apply flex-grow;
    @apply items-start;

    @media (min-width: 1280px) { 
      @apply flex-row;
    }
  }

  .footer-in-nav {
    @apply w-full;

    .footer-menu {
      @apply w-full;

      li {
        @apply inline;
        @apply w-auto;

        a {
          @apply mr-2.5;
          @apply relative;
          @apply inline-flex;
          @apply justify-center;

          &::after {
            content: '';
            @apply inline-block;
            @apply w-footer;
            @apply h-4;
            @apply ml-2.5;
          }
        }

        &:last-child {
          a {
            @apply mr-0;

            &::after {
              @apply hidden;
            }
          }
        }
      }
    }
  }
}
