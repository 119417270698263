.event-details-section {
  .event-details {
    p {
      @apply mb-10;
      @apply text-p-body1;
      @apply text-body1-dark;
      @apply font-wotfard-regular;
      @apply leading-body1;
    }

    h4 {
      @apply mb-2;
      @apply text-h4;
      @apply text-primary;
      @apply font-wotfard-medium;
      @apply leading-h4;
    }
  }
	
  .description-event-details {
    strong {
      @apply font-wotfard-medium;
    }
  }
}
