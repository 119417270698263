.event-overview {
  .event-content {
    @apply hidden;

    &.active {
      @apply block;
    }
  }

  .event-content-desktop {
    @apply hidden;

    &.active {
      @apply block;
    }
  }

  .event-category-button {
    @apply relative;
    @apply w-full;
    @apply text-left;
    @apply outline-none;
    @apply py-8;

    &:hover,
    &.active {
      @apply cursor-pointer;
      @apply text-primary;
      @apply font-wotfard-medium;
      @apply outline-none;

      .category-separator {
        @apply border-primary;
        @apply border-2;
        @apply border-solid;
      }
    }

    .category-separator {
      @apply absolute;
      @apply bottom-0;
      @apply left-0;
      @apply w-full;
      @apply border-solid;
      @apply border-white;
      @apply mb-4;
    }
  }
}
