.custom-swiper-btn-prev,
.custom-swiper-btn-next {
  @apply rounded-full;
  @apply relative;


  &.swiper-button-disabled {
    @apply text-navigation-button;
		
    &:hover {
      @apply bg-transparent;
			
      .custom-swiper-btn-border {
        @apply opacity-0;
      }

    }
  }

  .custom-swiper-btn-border {
    @apply absolute;
    @apply left-0;
    @apply top-0;
    @apply w-full;
    @apply h-full;
    @apply border-4;
    @apply rounded-full;
    @apply opacity-0;
  }

  &:hover {
    .custom-swiper-btn-border {
      @apply opacity-100;
    }
  }
}

.menu-slider {
  .swiper-pagination-bullet-active {
    background-color: #779b36;
  }
	
  .swiper-pagination-bullet {
    @apply mx-1;
  }	
}
