.rich-text-arrival {
  h2 {
    @apply text-h2-mobile;
    @apply leading-h2-mobile;
    @apply font-bebas-neue;
    @apply text-heading-dark;
    @apply mb-4;
    @apply mt-10;

    @screen md {
      @apply text-h2;
      @apply leading-h2;
      @apply font-bebas-neue;
      @apply text-heading-dark;
      @apply mb-4;
      @apply mt-10;
    }
  }

  body1 {
    @apply text-body1;
    @apply leading-body1;
    @apply font-wotfard-regular;
    @apply mb-2;
    @apply mt-10;
  }

  p {
    @apply mb-4;
    @apply text-p-body1;
    @apply text-body1-dark;
    @apply font-wotfard-regular;
    @apply leading-body1;

    @screen md {
      @apply mb-6;
      @apply text-p-body1;
      @apply text-body1-dark;
      @apply font-wotfard-regular;
      @apply leading-body1;
    }
  }
}

.text-information-section-image-right,
.textfield-image-text-card,
.information-list,
.information-section-small {
  h2 {
    @apply text-h2-mobile;
    @apply leading-h2-mobile;
    @apply font-bebas-neue;
    @apply text-heading-dark;
    @apply mb-6;
		

    @screen md {
      @apply text-h2;
      @apply leading-h2;
      @apply font-bebas-neue;
      @apply text-heading-dark;
      @apply mb-6;
    }
  }

  body1 {
    @apply text-body1;
    @apply leading-body1;
    @apply font-wotfard-regular;
    @apply mb-2;
    @apply mt-10;
  }

  p {
    @apply mb-2;
    @apply text-p-body1;
    @apply text-body1-dark;
    @apply font-wotfard-regular;
    @apply leading-body1;
  }

  ul {
    @apply list-disc;
    @apply pl-5;
  }

  li {
    @apply text-p-body1;
    @apply text-body1-dark;
    @apply font-wotfard-regular;
    @apply leading-body1;
    @apply mb-4;

    a {
      @apply underline;
      @apply text-allgemein;
    }
  }

  h4 {
    @apply mb-4;
    @apply text-p-body1;
    @apply text-body1-dark;
    @apply font-wotfard-regular;
    @apply leading-body1;
  }
}
