// global swiper flickering fix
.swiper-slide {
  /* stylelint-disable */
  -webkit-transform: translate3d(0, 0, 0);
  /* stylelint-enable */
}

// for media-queries use:
// 		@screen md {
// 			...
// 		}

.rich-text {
  h1 {
    @apply text-h1;
    @apply leading-h1;
    @apply font-wotfard-medium;
    @apply text-heading-dark;
    @apply mb-22;
    @apply mt-margin-top-content-mobile;

    @screen sm {
      @apply mt-10;
    }
  }

  h1-big {
    @apply text-h1-big;
    @apply leading-h1-big;
    @apply font-wotfard-medium;
    @apply text-heading-dark;
    @apply mb-22;
    @apply mt-margin-top-content-mobile;

    @screen sm {
      @apply mt-10;
    }
  }

  h2 {
    @apply text-h2-mobile;
    @apply leading-h2-mobile;
    @apply font-bebas-neue;
    @apply text-heading-dark;
    @apply mb-4;
    @apply mt-10;

    @screen md {
      @apply text-h2;
      @apply leading-h2;
      @apply font-bebas-neue;
      @apply text-heading-dark;
      @apply mb-4;
      @apply mt-10;
    }
  }

  h2-big {
    @apply text-h2-big;
    @apply leading-h2-big;
    @apply font-bebas-neue;
    @apply text-heading-dark;
    @apply mb-4;
    @apply mt-10;
  }

  h3 {
    @apply text-h3;
    @apply leading-h3;
    @apply font-wotfard-regular;
    @apply text-heading-dark;
    @apply mb-4;
    @apply mt-10;
  }

  h4 {
    @apply text-h4;
    @apply leading-h4;
    @apply font-wotfard-regular;
    @apply text-heading-dark;
    @apply mb-2;
    @apply mt-10;
  }

  introtext {
    @apply text-introtext;
    @apply leading-introtext;
    @apply font-wotfard-regular;
    @apply mb-2;
    @apply mt-10;
  }

  body1 {
    @apply text-body1;
    @apply leading-body1;
    @apply font-wotfard-regular;
    @apply mb-2;
    @apply mt-10;
  }

  body1-bold {
    @apply text-body1-bold;
    @apply leading-body1-bold;
    @apply font-wotfard-regular;
    @apply mb-2;
    @apply mt-10;
  }

  body2 {
    @apply text-body2;
    @apply leading-body2;
    @apply font-wotfard-regular;
    @apply mb-2;
    @apply mt-10;
  }

  p {
    @apply text-p-body1;
    @apply text-body1-dark;
    @apply font-wotfard-regular;
    @apply mb-8;
    @apply leading-body1;
  }
}

.button-ghost {
  a {
    @apply border-2;
    @apply border-solid;
    @apply rounded-btn-ghost;
    @apply py-2;
    @apply px-8;
    @apply inline-block;
    @apply relative;
  }

  a {
    &:hover {
      @apply transform;
      @apply scale-105;
    }
  }
}

.button-primary {
  a {
    @apply inline-block;
    @apply relative;
  }

  a {
    &:hover {
      @apply transform;
      @apply scale-105;
    }
  }
}

.map {
  .img-pin {
    &:hover {
      transform: scale(1.025) perspective(1px);
      @apply transition;
      @apply duration-300;
      @apply ease-in-out;
    }
  }
}

.buttons-event {
  button {
    &:focus {
      @apply text-white;
    }
  }

  .filter-button-8 {
    @apply ml-4;

    @screen lg {
      @apply ml-filter-buttons;
    }
  }
}

.banner-image {
  .video-banner {
    iframe {
      @apply w-full;
      @apply h-video-mobile;

      @screen md {
        @apply h-video-desktop;
      }
    }
  }
}

.pdf-field {
  .optimized-image-crop-wrapper {
    padding-top: 155%;
  }
}

.pdf-field-optimized-image {
  .optimized-image-crop-wrapper {
    padding-top: 155%;
  }
}
