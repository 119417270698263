footer {
  @apply bg-white;
  @apply text-footer;
  @apply p-4;
  @apply relative;
  @apply bottom-0;
  @apply w-screen;
  @apply h-footer;
  @apply border-t;
  @apply border-grey;
  @apply border-solid;

  @media (min-width: 640px) {
    @apply py-4;
    @apply px-27;
    @apply h-14;
  }

  .footer-menu {
    li {
      a {
        @apply text-footer;
        @apply mr-4;
        @apply relative;
        @apply flex;
        @apply justify-center;

        &::after {
          content: '';
          @apply inline-block;
          @apply w-footer;
          @apply h-4;
          @apply bg-body2;
          @apply ml-4;
          @apply mt-1;
        }
      }

      &:last-child {
        a {
          @apply mr-0;

          &::after {
            @apply hidden;
          }
        }
      }
    }
  }
}
