.contact-details-wrapper {
  display: none;

  &.active {
    display: flex;

    @screen lg {
      @apply flex-row;
      @apply justify-start;
    }
  }
}

.buttons-kontakt {
  &:focus,
  &.active {
    @apply text-white;
  }
}

.social-media {
  svg {
    border: solid 1px #9b9b9b;
    padding: 6px;

    &:hover {
      border-color: transparent;
      @apply text-white;
    }
  }
}

.contact-section-buttons {
  button {
    @apply bg-white;

    &.bg-seeseitn {
      background-color: theme('colors.seeseitn');
    }

    &.bg-mostvierterl {
      background-color: theme('colors.mostvierterl');
    }

    &.bg-jetlake {
      background-color: theme('colors.jetlake');
    }

    &.bg-dassee {
      background-color: theme('colors.dassee');
    }
  }
}
